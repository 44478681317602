export const servicesall={
   
 Icon1: '/images/csohajlitas-4.jpg',
 Icon2 : '/images/elhajlitas_es_lemez_megmunkalas-1.jpg',
 Icon3 : '/images/elektrosztatikus_porszoras.jpg',
 Icon4 : '/images/csodarabolas-3.jpg',
 Icon5 : '/images/koordinata_lyukasztas-1.jpg',
 Icon6 : '/images/furesztarcsa_elezes-4.jpg',
 Icon7 : '/images/forgacsolas-4.jpg',
 Icon8 : '/images/lezervagas-3.jpg',
 Icon9 : '/images/kemiai_tisztitas.jpg',
 Icon10 : '/images/hegesztes.jpg',
 Icon11 : '/images/szemcseszoras.jpg',
 Icon12 : '/images/plazmavagas-4.jpg',
  
  };