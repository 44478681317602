import React from 'react'
import ScrollToTop from '../components/ScrollToTop'

const PrivacyPolicy = () => {
  return (
    <>
        <ScrollToTop/>
    </>
  )
}

export default PrivacyPolicy